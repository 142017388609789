import React, { useEffect } from 'react'
import axios from 'axios'

import Layout from 'components/layout'
import SEO from 'components/seo'
import { Heading, Box } from 'rebass/styled-components'

const ThankYouPage = () => {
  useEffect(() => {
    const retrieveSession = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const sessionId = urlParams.get('session_id')
      const amount = document.getElementById('amount')

      if (sessionId) {
        const { err, data } = await axios({
          method: 'GET',
          url: '/.netlify/functions/stripe-retrieve-session?id=' + sessionId,
        })

        if (err) {
          console.log('[error]', err)
        }

        amount.innerText = data.id
      }
    }
    retrieveSession()
  }, [])

  return (
    <Layout>
      <SEO title="Thank you" />
      <Heading textAlign="center" fontSize={[4, 4, 5]} py={3}>
        Thank you for your purchase!
      </Heading>
      <Box id="amount"></Box>
    </Layout>
  )
}

export default ThankYouPage
